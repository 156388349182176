<template>
  <div class="page">
    <nav-bar PitchOn="关于我"></nav-bar>
    <div class="main-info">
      <div class="about-info">
        <div class="info-title">
          <span>关于我</span>
        </div>
        <div class="info-content" v-html="info.about_content">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar/NavBar";
import {onMounted, reactive, toRefs} from "vue";
import {AboutMe} from "../../api/api";
export default {
  name: "AboutMe",
  components: {NavBar},
  setup(){
    const data = reactive({
      info: {},
      /**
       * 获取数据
       */
      getData(){
        AboutMe({

        }).then(res =>{
          data.info = res.data;
        })
      }
    })
    onMounted(() =>{
      data.getData();
    })
    return {
      ...toRefs(data)
    }
  }
}
</script>

<style scoped lang="scss">
@import "AboutMe.scss";
</style>
